
/*
 * RISE 
 * Page | Video 
 * 
 * Important:
 * <link rel="stylesheet" href="https://cdn.plyr.io/3.7.8/plyr.css" />
 */

import Plyr from 'plyr';
import { AdjustTrackingUrl } from "../util/tracking/adjustTrackingUrl";
import { AdjustTrackingQr } from "../adjust-qr";
import { AdjustTrackingUrls } from '../globals';
import { IModule } from "@sygnal/sse";

const url: string = 'https://www.sygnal.com';


export class VideoPage implements IModule {

    constructor() {
    }
    
    setup() {
        
    }
  
    exec() {

      console.log("RISE video page init."); 

      /**
       * QR Code on Video CTA
       */ 

      const canvases = document.querySelectorAll("[video-cta-qr]");
      canvases.forEach((canvasElement) => {

        if(!(canvasElement instanceof HTMLCanvasElement))
          return; // continue

        const canvas = canvasElement as HTMLCanvasElement;

        const qr: AdjustTrackingQr = new AdjustTrackingQr();
        qr.loadFromElement(canvasElement);
        qr.generate(canvas); 
        
      });

      /**
       * Popup + Video player
       */

      // IMPORTANT:
      // Make sure CSS is in the page... 
      // <link rel="stylesheet" href="https://cdn.plyr.io/3.7.8/plyr.css" /> 

  //        console.log("installin video player"); 

      const players = document.querySelectorAll("[video-player]");
      players.forEach((elem) => {

        if(!(elem instanceof HTMLElement))
          return; // continue

        const player = new Plyr(elem, {});

        // Attach event listeners for play and pause events
        player.on('play', event => {
          console.log('play');
        });

        player.on('pause', event => {
          console.log('pause');
        });

        player.on('ended', event => {
          console.log('The video has ended.');
        });        

        // Create a MutationObserver instance
        const observer = new MutationObserver((mutations: MutationRecord[]) => {
          mutations.forEach((mutation: MutationRecord) => {
            if (mutation.attributeName === 'style') {
              // Assuming mutation.target is an HTMLElement
              const target = mutation.target as HTMLElement;
              const displayStyle = window.getComputedStyle(target).display;
              if (displayStyle === 'none') {
                // Find the index of the hidden div
                const index = Array.from(target.parentNode!.children).indexOf(target);
                // Pause the corresponding video
                player.pause();
              }
            }
          });
        });

        // Select all elements with the class 'fs_modal-1_popup' and observe each for changes
        document.querySelectorAll('.fs_modal-1_popup').forEach((elem, index) => {
          console.log("Installing observer for element at index:", index);
          observer.observe(elem, {
            attributes: true,
            attributeFilter: ['style'] // If you only want to observe changes to 'style' attribute
          });
        }); 

      });

    }

}  

    
    
    
    