
/*
 * RISE 
 * Component | CC
 * 
 */

import { Debug } from "../debug";
import { IModule, Page } from "@sygnal/sse";
import posthog from 'posthog-js'

//const url: string = 'https://www.sygnal.com?test';


export class CookieConsentComponent implements IModule {

//    private debug: Debug = new Debug("blog", "RISE");

    constructor() {
    }

    setup() {

//        console.log("loading blog CSS"); 
//        Page.loadEngineCSS("blog.css"); 

//      console.log("Blog | Setup");

    }
  
    exec() {

//      console.log("Blog | Exec");
      


//        this.debug.enabled = true; // Debug 

        /**
         * Add GTM tracking to social share links
         */

//         ph-event
// //        ph-event-trigger click | scroll into view
//         ph-event
//         cc-accept-all
//         cc-close
//         cc-customize
        
        // // Find all elements with the 'ph-event' custom attribute
        // const elements = document.querySelectorAll('[ph-event]');

        // // Iterate through the elements and install a click event handler
        // elements.forEach((element) => {
        //     const eventName = element.getAttribute('ph-event'); // Get the event name from the attribute
        //     if (eventName) {
        //         element.addEventListener('click', () => {
        //             // Fire a PostHog event when the element is clicked
        //             posthog.capture(eventName, {});
        //             console.log(`PostHog event '${eventName}' fired!`);
        //         });
        //     }
        // });

    }

}  

    
    
    
    