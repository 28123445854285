
/*
 * Site
 */

import { IModule, Page } from "@sygnal/sse";
import { Banner } from "./banner";
import { GTM } from "./gtm";
import { Modal } from "./modal";
import { DesktopCta } from "./cta-desktop";
import posthog from 'posthog-js'
import { CookieConsentComponent } from "./components/cookie-consent";

// import gsap from 'gsap'; 
 

export class Site implements IModule {

  cookieConsent: CookieConsentComponent = new CookieConsentComponent(); 

  constructor() {
  }

  setup() {

    console.log("Site | Setup");

    Page.loadEngineCSS("site.css"); 

    // Init Posthog
//    posthog.init('phc_sDyfjvWOV3pbh9EnXhMVjqNdFM1JkswxykWIzzleHwy', { api_host: 'https://us.i.posthog.com', person_profiles: 'identified_only' })

    console.log("ph loaded?", posthog.__loaded); 

    posthog.onFeatureFlags(() => {
      console.log("PostHog feature flags are loaded, indicating initialization");
  });
  

    this.cookieConsent.setup(); 
    
  }

  exec() {

    console.log("Site | Exec");

    // Remove Design-time elements
    document.querySelectorAll('[wfu-design]').forEach((element) => {
      element.remove();
    });
  
    // Banner setup 
    // Creates Adjust tracking URLs for each banner 
    (new Banner()).safeInit();
 
    // GTM tracking init
    (new GTM()).safeInit();

    // Modals
    // Add close-button handler to all identified elements
    // Suppress closed 'modals'.  
    (new Modal()).safeInit();  

    // Desktop CTA Modal
    // and QRs 
    (new DesktopCta()).safeInit();

//    this.cookieConsent.exec(); 
    this.installPosthogTrackers(); 

    // Put your site-level custom code here
    // it will have full access to the DOM 

  }

  installPosthogTrackers() {

    // Find all elements with the 'ph-event' custom attribute
    const elements = document.querySelectorAll('[ph-event]');

    // Iterate through the elements and install a click event handler
    elements.forEach((element) => {
        const eventName = element.getAttribute('ph-event'); // Get the event name from the attribute
        if (eventName) {
            element.addEventListener('click', () => {
                // Fire a PostHog event when the element is clicked
                posthog.capture(eventName, {});
                console.log(`PostHog event '${eventName}' fired!`);
            });
        }
    });

  }

}
